#root .majorLabel, #root h5 {
  font-size: 0.8rem;
  color: #0a0a0a;
  display: block;
  margin-bottom: 0.4em;
}

#root h5 {
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}

.dayTimeBox {
  height: 40px;
}

.radio, .daysCheckbox {
  margin-right: 5px;
}

.noOption {
  margin-left: 15px;
}

.inputBox {
  border-radius: 30px;
  height: 40px;
  width: 100%;
}

.selectBox {
  border-radius: 10px;
  width: 100%;
}

#subjects{
  height: calc(100% - 50px);
  max-height: 627px;
}

#instructors{
  height: 60%;
  max-height: 368px;
}

.option-loading{
  background-image: url(https://media.giphy.com/media/yWyFLWrVejSPFTtS1L/giphy.gif);
  height: 368px;
  background-position: center;
  background-repeat: no-repeat;
}

#resetBtn {
  float: right;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url("https://www.aims.edu/themes/custom/aims/images/icons/calendar-red.png");
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background-image: url("https://www.aims.edu/themes/custom/aims/images/icons/clock.svg");
}

@media (min-width: 37.5em) {
  #root .l-section.l-section--four-col .l-section__grid .l-section__col:first-of-type {
    grid-column: 1 / span 5;
  }
}

@media (min-width: 37.5em) {
  #root .l-section.l-section--four-col .l-section__grid .l-section__col:nth-of-type(2) {
    grid-column: 7 / span 5;
  }
}

@media (min-width: 37.5em) {
  #root .l-section.l-section--four-col .l-section__grid .l-section__col:nth-of-type(3) {
    grid-column: 13 / span 5;
  }
}

@media (min-width: 37.5em) {
  #root .l-section.l-section--four-col .l-section__grid .l-section__col:nth-of-type(4) {
    grid-column: 19 / span 5;
  }
}

select::after{
  content: '' !important;
}

option{
  white-space: pre-wrap;
}

#root .majorLabel.filter-working{
  color: #141dd2;
}

.modal{
  position: absolute;
  width: 60%;
  height: 60%;
  max-height: 300px;
  max-width: 600px;
  background: rgba(0,0,0,0.9);
  display: block;
  z-index: 1;
  border-radius: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.help-text{
  font-size: small;
  float: left;
  font-style: italic;
}

.tooltip{
  position: relative;
  float: left;
  margin-top: 10px;
  margin-right: 5px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 2px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -40px;
  left: 0px;
  font-size: 12px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

#submitBtn {
  text-decoration: none;
  border-radius: 500px;
  border: 0;
  background-blend-mode: multiply;
  text-align: center;
  fill: #fff;
  padding: 8px 24px;
  color: #fff;
  background-color: #215691;
  transition: color 0.2s ease-out;
  font-family: "Inter", sans-serif;
}

#hr_scroll {
  margin-top: 16px;
}
