
/*Course Results*/
.course:first-of-type {
  border: 1px solid #D2DEE3;
}

.course-title{
  margin: 32px 0;
}

.course {
  border: 1px solid #D2DEE3;
  border-top: none;
  padding: 20px;
  font-size: 14px;
}

.course-card__header{
  margin-bottom: 8px;
}

#root .course-card__show-more__heading h5{
  margin-bottom: .6rem;
}

.course-card__header p,
.course-card__footer p{
  display: inline;
  padding: 0px 10px;
}

.course-card__header__sisid{
  grid-column-start: 1;
  grid-column-end: 3;
  font-size: 16px;
  font-weight: bold;
}

.course-card__header__section{
  font-size: 18px;
}

.course-card__header__credits span{
  font-weight: bold;
}

.course-card__header__instructor span{
  font-weight: bold;
}

.course-card__header__available{
  float: right;
}

.course-card__header__available span{
  font-weight: bold;
  padding-left: 5px;
}

.course-card__header__section_status{
  float: right;
  background-color: #FFDE17;
  text-transform: uppercase;
}

.status_closed{
  background-color: #da1418;
  color: #fff;
}

.more-details-closed,
.more-details-open{
  float: right;
  background-color: #D2DEE3;
  border-radius: 50%;
  margin-left: 15px;
  position: relative;
  width: 23px;
  height: 23px;
  cursor: pointer;
  border: none;
}

.more-details-closed span.arrow{
  border: solid #215691;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  top: -3px;
  position: relative;
  pointer-events: none;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.more-details-open span.arrow{
  border: solid #215691;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  pointer-events: none;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.course-card__footer__class-days span{
  padding-right: 5px;
}

.course-card__footer__class-days span.class-scheduled{
  font-weight: bold;
}

.course-card__footer__class-days span.class-not-scheduled{
  opacity: .7;
}

.show-more-details-false{
  display: none;
}
.show-more-details-true{
  display: block;
  background-color: #f3f6f8;
  padding: 0.5em 2em;
  margin-top: 1em;
}

.course-card__show-more__heading{
  display: inline-flex;
}

.course-card__show-more__section-information p{
  margin: 10px 0;
}

.course-card__show-more__section-information,
.course-card__show-more__important-dates{
  display: flex;
}

.section-information__col-1,
.section-information__col-2{
  width: 50%;
  padding-left: 25px;
  padding-right: 2em;
}

.important-dates__col-1,
.important-dates__col-2,
.important-dates__col-3{
  width: 33%;
  padding-right: 2em;
  padding-left: 25px;
}

.course-card__show-more__term{
  padding-left: 25px;
}

.course-card__show-more__textbook{
  padding-left: 25px;
}

.course-card__show-more__online-help{
  padding-left: 25px;
}

p.crosslist-seating{
  padding-left:25px;
  margin:0;
}

.icon{
  width: 15px;
  position: relative;
  top: 3px;
  margin-right: 2px;
}

.icon-large{
  width: 15px;
  height: 100%;
  top: 10px;
  margin-right: 11px;
}

@media screen and (max-width: 800px){
  .course{
    position: relative;
  }
  .course-card__header p,
  .course-card__footer p{
    display: block;
  }
  .course-card__header__section_status,
  .course-card__header__available{
    float:none;
  }
  .more-details-closed,
  .more-details-open{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .course-card__header .course-card__header__section_status{
    display: inline-block;
    margin-left: 10px;
  }
  .course-card__header__sisid{
    float: left;
  }

  .course-card__show-more__section-information,
  .course-card__show-more__important-dates{
    display: block;
  }

  .section-information__col-1,
  .section-information__col-2{
    width: 100%;
    padding-left: 25px;
    padding-right: 2em;
  }

  .important-dates__col-1,
  .important-dates__col-2,
  .important-dates__col-3{
    width: 100%;
    padding-right: 2em;
    padding-left: 25px;
  }
}
