.l-section--normal > .l-section__inner {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.header.l-section__col.l-section__col-- {
    display: flex;
    align-items: center;
}

.c-site-header__logo a, .c-site-header__logo img {
    width: 60%;
}

/*@media (min-width: 92em) {*/
/*    .c-site-header__logo a, .c-site-header__logo img {*/
/*        width: 70%;*/
/*    }*/
/*    .c-site-header__name:before {*/
/*        margin: 0 30px 0 -50px !important;*/
/*    }*/
/*}*/

@media (max-width: 1000px) {
    .primary-nav ul {
        line-height: normal;
        align-items: center;
        /*justify-content: space-evenly;*/
    }
}

@media (max-width: 500px) {
    .c-site-header__logo a, .c-site-header__logo img {
        width: 60%;
    }
    .c-site-header__name {
        font-size: 30px;
    }
    .c-site-header__name:before {
        margin: 0 30px 0 -80px !important;
    }
    .primary-nav ul li a {
        font-size: 20px;
    }
}

@media (max-width: 400px) {
    .c-site-header__name:before {
        margin: 0 20px 0 -60px !important;
    }
}

.c-site-header__name {
    font-weight: bold;
    display: flex;
    align-items: center;
}

.c-site-header__name:before {
    content: "";
    display: block;
    background-color: lightgray;
    width: 2px;
    height: 4rem;
    transform: rotate(18deg);
    margin: 0 30px 0 -80px;
}

.primary-nav {
    background: #222020;
    height: 64px;
    align-content: center;

    & ul {
        display: flex;
        /*margin-left: -16px;*/
        padding-inline-start: 0px;

        & a {
            text-decoration: none;
            color: #fff;
            font-size: x-large;
            font-weight: bold;
            margin-right: 32px;
        }

        & a:hover {
            text-decoration: underline;
        }
    }
}