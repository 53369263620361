.sub-footer {
    background: #222020;
    color: white;
}

.sub-footer.l-section--normal.t-normal.t-gutter-normal.t-.l-section {
    margin-top: 16px !important;
    max-width: 100%;
}

.footer-l-constrained div:first-child {
    display: flex;
    justify-content: space-between;
}

.footer-l-constrained div:nth-child(2) {
    display: flex;
}

.footer-l-constrained > div > div  {
    padding: 25px 0;
}

#block-footerbottomaimsaddress > div > p > a {
    color: white;
}

#block-footerbottomaimsaddress > div > p {
    margin: 0;

    > span {
        margin: 0 4px;
    }
}

#block-footerbottomcopyright span {
    font-size: 16px;
}

@media (max-width: 1000px) {
    #block-footerbottomcopyright *{
        text-align: center;
    }

    #block-footerbottomaimsaddress * {
        text-align: center;
    }

    .footer-l-constrained.l-section__inner > .l-section__col.l-section__col-- {
        flex-direction: column;
        align-items: center;
    }
}