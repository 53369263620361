#root p,
#root input,
#root option,
#root label{
  font-size: 16px;
}

#root{
  min-height: 500px;
}
