.c-results-filter__container{
    display: inline-flex;
    flex-wrap: wrap;
}

.c-results-filter__object-container{
    padding: .5em;
}

li.u-tag{
    padding: 0.2em 0.5em;
}

.c-results-filter-display__filter-title{
    font-weight: 600;
}

ul.c-results-filter-display__filters{
    padding-inline-start: 0px;
}
